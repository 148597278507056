html, body {
    overscroll-behavior-x: none;
    font-family: Montserrat, sans-serif!important;
}

p{
    margin: 0;
}

#react-target{

}

.rs-picker-menu {
    z-index: 1500!important;
}

.rdrDefinedRangesWrapper{
    display: none;
}

.loading-wrapper{
    position: fixed;
    width: 100%!important;
    height: 100%!important;
    left: 0;
    top: 0;
    display: flex!important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loading-wrapper.loading-wrapper--with-app-bar{
    top: 50px;
    height: calc(100% - 50px)!important;
}

.client__alert-not-active {
    display: none;
    width: calc(100% - 32px);
    position: absolute;
    top: 11px;
    align-items: center;
    font-size: 0.8rem;
    justify-content: center;
    color: #000;
}


input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none!important;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield!important;
}

#lightboxContainer section.imageModal{
    //display: flex;
    //justify-content: flex-end;
}

@media (min-width: 450px) {

    .makeStyles-dropZone-81{
        padding: 32px!important;
        padding-bottom: 50px!important;
    }

    .makeStyles-thumbWrapper-82{
        width: calc(25% - 15px)!important;
    }

    .makeStyles-thumbWrapper-82{
        margin-right: 20px!important;
    }

    .makeStyles-thumbWrapper-82:nth-child(3n){
        margin-right: 20px!important;
    }

    .makeStyles-thumbWrapper-82:nth-child(4n){
        margin-right: 0!important;
    }


}

@media (max-width: 703px) {


    .makeStyles-markupEditableWrapper-174{
        width: calc(100% - 135px);
    }

}